import React, { useState, useRef, useEffect } from "react"
import { Plock } from "react-plock"
import { Helmet } from "react-helmet"

import Header from "../components/Header"
import Footer from "../components/Footer"
import MemeCard from "../components/MemeCard"
import Categories from "../components/Categories"

import profilePicture from "../images/dago.png"
import * as styles from "./AllMemes.module.css"
import * as styles2 from "./JourneyPage.module.css"

const CategoryPage = ({ pageContext }) => {
  const inputRef = useRef(null)

  const { allMemes, memeCount } = pageContext
  const categoryMemes = allMemes.filter(meme => {
    if (meme.themes !== undefined) {
      return meme.themes.includes(pageContext.title)
    }
  })

  const [visibleMemes, setVisibleMemes] = useState(categoryMemes)
  const [categoriesVisible, setCategoriesVisible] = useState(true)
  const [hideHeaderPart, setHideHeaderPart] = useState(false)

  const breakpoints = [
    { size: 640, columns: 1 },
    { size: 768, columns: 2 },
    { size: 1024, columns: 3 },
  ]

  const searchHandler = () => {
    const search = inputRef.current.value.trim()
    const filteredMemes = allMemes.filter(meme => {
      if (meme.caption.toLowerCase().includes(search.toLowerCase())) {
        return true
      }
      return (
        meme.themes &&
        meme.themes.some(theme => {
          if (theme.toLowerCase().includes(search.toLowerCase())) {
            return true
          }
          return false
        })
      )
    })
    setVisibleMemes(
      filteredMemes.sort(
        (a, b) => b.tweetPerformance.likes - a.tweetPerformance.likes
      )
    )
  }

  const clearHandler = () => {
    inputRef.current.value = ""
    setVisibleMemes(pageContext.categoryMemes)
  }

  useEffect(() => {
    if (inputRef.current && inputRef.current.value) {
      setCategoriesVisible(false)
    } else {
      setCategoriesVisible(true)
    }
  }, [visibleMemes])

  useEffect(() => {
    sessionStorage.removeItem("lastViewedMeme")
  }, [])

  const toggleHeaderPartVisibility = state => {
    setHideHeaderPart(state)
  }

  return (
    <>
      {!hideHeaderPart && <Header />}
      <Helmet>
        <title>{pageContext.title} - startup memes</title>
        <meta
          name="description"
          content={`Fun memes about ${pageContext.title} in the startup journey.`}
        />
      </Helmet>
      <section className={styles.memes}>
        <div className={styles.container}>
          {/* {!hideHeaderPart && (
                        <>
                            <h1>Meme Database</h1>
                            <div className={styles.searchbar}>
                                <input onChange={searchHandler} type="text" placeholder="Search" ref={inputRef} />
                                <button onClick={clearHandler} style={{
                                    opacity: !categoriesVisible ? 1 : 0
                                }}>clear</button>
                            </div>
                        </>)} */}
          <div className={styles2.intro}>
            <img src={profilePicture} alt="Dagobert" />
            <p>
              Hi, I'm Dagobert 👋
              <br />I build startups, create memes, and teach founders{" "}
              <a href="https://www.dagorenouf.com/how-to-dominate-twitter/">
                how to grow on twitter/x
              </a>
              .
            </p>
          </div>
          {categoriesVisible && (
            <>
              <Categories
                memeCount={memeCount}
                memes={allMemes}
                currentPageTitle={pageContext.title}
                toggleHeaderPartVisibility={toggleHeaderPartVisibility}
              />
              <h2
                style={{ marginTop: "1rem" }}
                className={styles.categoryTitle}
              >
                {pageContext.title}
              </h2>
            </>
          )}
          {typeof window !== "undefined" && (
            <div
              style={{
                minHeight: visibleMemes.length > 4 ? "auto" : "80vh",
              }}
            >
              <Plock nColumns={breakpoints} gap="2rem">
                {visibleMemes.map((meme, index) => (
                  <MemeCard
                    key={index}
                    id={meme.id}
                    type={meme.type}
                    poster={meme.poster}
                    media={meme.media}
                    date={meme.date}
                    themes={meme.themes}
                    themeColor={meme.themeColor}
                    setLastViewedMeme={() => {}}
                  />
                ))}
              </Plock>
            </div>
          )}
        </div>
      </section>
      <Footer />
    </>
  )
}

export default CategoryPage
